import axios from 'axios'
import config from '@/config'

export default {
  name: 'TFileUpload',

  data () {
    return {
      file: '',
      url: ''
    }
  },

  methods: {

    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      if (this.$refs.file.files.length > 0) {
        this.$emit("fileChanged", this.file)
        this.submitFile()
      }
    },

    submitFile () {
      const token = localStorage.getItem('token')
      let config = {
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          this.$emit("progress", percentCompleted)
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token ? `Bearer ${token}` : ''
        }
      }

      let formData = new FormData()
      formData.append('input_file', this.file)

      axios.post(this.url, formData, config).then(e => {
        Vue.$log.info('success', e)
        this.$emit('success', e)
      }).catch(e => {
        Vue.$log.error('error', e)
        this.$emit('error', e)
      })
    }
  },

  mounted () {
    this.url = `${config.collectEndpointUrl}/admin/upload_input_file`
  }
}
